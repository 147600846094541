.web{
    display: flex;
    flex: 1;
    align-items: center;
}

.web-option{
    font-size: 20px;
    margin-right: 16px;
    font-weight: 600;
}

.web-option a {
    text-decoration: none;
    display: flex;
    color: black;
}

.option-icon{
    display: flex;
    align-items: center;
    margin-right: 4px;
    height: 1.2em;
    width: 1.2em;
}

.web-option:last-child{
    margin-right: 0px;
}

