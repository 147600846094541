.header{
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 12px;
    position: relative;
    align-items: center;
}


.menu{
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.menu-icon{
    font-size: 45px;
    height: 33px;
    width: 33px;
}

.mobile-menu{
    display: none;
}

.web-menu{
    display: flex;
}

.logo{
    font-family: 'Dancing Script', cursive;
    display: flex;
    font-size: 36px;
    font-weight: 800;
}

@media only screen and (max-width: 720px){
    .mobile-menu{
        display: flex;
    }
    .web-menu{
        display: none;
    }
    .logo{
        font-size: 36px;
    }
}