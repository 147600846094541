.about{
    padding-bottom: 48px;
}

.about-top{
    display: flex;
    /*flex-direction: column-reverse;*/
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.about-info{
    /*padding: 40px 40px 40px 40px;*/
    padding: 2rem 2rem 2rem 2rem;
    font-size: 18px;
    text-align: justify;
}

.picture{
    height: 420px;
}

.info-name{
    color: blue;
    font-size: 32px;
}

@media only screen and (max-width: 720px){
    .about-top{
        flex-direction: column-reverse;
        justify-content: center;
        align-self: center;
    }

    .picture{
        height: 280px;
    }

    .about-info{
        font-size: 16px;
    }

    .info-name{
        font-size: 26px;
    }
}