.footer{
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    font-size: 24px;
}

@media only screen and (max-width: 720px) {
    .footer{
        font-size: 18px;
    }
}